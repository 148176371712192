import { createSlice } from '@reduxjs/toolkit'


export const listLinesSlice = createSlice({
  name: 'lines',
  initialState: {
    value: ['B'],
  },
  reducers: {
    
    selectedLines: (state, action) => {

      if (action.payload.length > 0){
        state.value = action.payload
      } else{
        state.value = ['B']
      } 
      
    },
  },
})

// Action creators are generated for each case reducer function
export const { selectedLines } = listLinesSlice.actions

export default listLinesSlice.reducer