// store.js
import { configureStore } from '@reduxjs/toolkit'

import anioReducer  from './anioSlice'
import mesReducer from './mesSlice'
import brandReducer from './brandSlice'
import brandInventoryReducer from './brandInventorySlice'
import lineReducer from './lineSlice'
import lineInventoryReducer from './lineInventorySlice'
import listYearsReducer from './listYearsSlice'
import listMothsReducer from './listMothsSlice'
import listBrandsReducer from './listBrandSlice'
import listLinesReducer from './listLinesSlice'
import listBrandsInventoryReducer from './listBrandsInventorySlice'
import listLinesInventoryReducer from './listLinesInventorySlice'

export default configureStore({
  reducer: {
    anio: anioReducer,
    mes: mesReducer,
    brand: brandReducer,
    line: lineReducer,
    brandInventory: brandInventoryReducer,
    lineInventory: lineInventoryReducer,
    years: listYearsReducer,
    months: listMothsReducer,
    brands: listBrandsReducer,
    lines: listLinesReducer,
    brandsInventory: listBrandsInventoryReducer,
    linesInventory: listLinesInventoryReducer
  },
})
