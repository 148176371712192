import { createSlice } from '@reduxjs/toolkit'

let mesNumero = null;
const fechaActual = new Date();

  // Obtener el mes actual (devuelve un número entre 0 y 11)
  const mesActual = fechaActual.getMonth();
  
  // Crear un arreglo para almacenar los meses transcurridos
  const mesesTranscurridos = [];
  
  // Iterar desde enero (mes 0) hasta el mes actual (incluido)
  for (let i = 0; i <= mesActual; i++) {
    mesesTranscurridos.push(i + 1); // Sumar 1 para ajustar al rango de 1 a 12
  }

export const listMonthSlice = createSlice({
  name: 'months',
  initialState: {
    value: mesesTranscurridos,
    name:''
  },
  reducers: {
    
    monthSelected: (state, action) => {

      function mesesANumerosOrdenados(meses) {
        // Objeto que mapea nombres de meses a números
        const mesesDict = {
            'Enero': 1,
            'Febrero': 2,
            'Marzo': 3,
            'Abril': 4,
            'Mayo': 5,
            'Junio': 6,
            'Julio': 7,
            'Agosto': 8,
            'Septiembre': 9,
            'Octubre': 10,
            'Noviembre': 11,
            'Diciembre': 12
        };
    
        // Convertir nombres de meses a números
        const numerosMeses = meses.map(mes => mesesDict[mes]);
    
        // Ordenar los números de los meses
        numerosMeses.sort((a, b) => a - b);
    
        return numerosMeses;
    }
      if (action.payload.length > 0){
        const mesesNumerosOrdenados = mesesANumerosOrdenados(action.payload);
        state.value = mesesNumerosOrdenados
       
      } else{
        state.value = mesesTranscurridos
      } 
     

     
    },

    monthNameSelected: (state, action) => {
      state.name = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const { monthSelected,  monthNameSelected} = listMonthSlice.actions

export default listMonthSlice.reducer