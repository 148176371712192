import { createSlice } from '@reduxjs/toolkit'


export const listLinesInventorySlice = createSlice({
  name: 'linesInventory',
  initialState: {
    value: ['B'],
  },
  reducers: {
    
    selectedLinesInventory: (state, action) => {

      if (action.payload.length > 0){
        state.value = action.payload
      } else{
        state.value = ['B']
      } 
      
    },
  },
})

// Action creators are generated for each case reducer function
export const { selectedLinesInventory } = listLinesInventorySlice.actions

export default listLinesInventorySlice.reducer