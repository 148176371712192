import { createSlice } from '@reduxjs/toolkit'


export const brandSlice = createSlice({
  name: 'brand',
  initialState: {
    value: 'A',
  },
  reducers: {
    
    brandSelected: (state, action) => {
      state.value = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const { brandSelected} = brandSlice.actions

export default brandSlice.reducer