import { createSlice } from '@reduxjs/toolkit'


export const listBrandsInventorySlice = createSlice({
  name: 'brandsInventory',
  initialState: {
    value: ['A'],
  },
  reducers: {
    
    selectedBrandsInventory: (state, action) => {

      if (action.payload.length > 0){
        state.value = action.payload
      } else{
        state.value = ['A']
      } 
      
    },
  },
})

// Action creators are generated for each case reducer function
export const { selectedBrandsInventory } = listBrandsInventorySlice.actions

export default listBrandsInventorySlice.reducer