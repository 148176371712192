import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';


import {  useDispatch, useSelector } from 'react-redux';
import { Select } from '@mui/material';
import { selectedLines } from 'layouts/store/listLinesSlice';
import { useApiFetch } from 'hooks/useApiFetch';


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};



function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const key = 'b8d89953f379f97616201f31e173bab6d7ea444f0b7adbef2ccc7c7ec8cb6a34'
export default function SelectMultiLines() {

  const { get } = useApiFetch();
  const dispatch = useDispatch();
  const theme = useTheme();
  const [personName, setPersonName] = React.useState([]);

  const selectedYears = useSelector((state) => state.years.value);
  const selectedMonths = useSelector((state) => state.months.value);
  const SelectBrands = useSelector((state) => state.brands.value);
  const [lineas, setLineas] = React.useState(["B"]);
 

  React.useEffect(() => {
  
    const fetchData = async () => {
      try {
        const responseApi = await get(`/api/Boards/GetSelectLinea?listaAnios=${JSON.stringify(selectedYears)}&listaMeses=${JSON.stringify(selectedMonths)}&listaMarcas=[${SelectBrands}]&key=${key}`)
        setLineas(responseApi);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
  };

  fetchData();
}, [selectedYears,selectedMonths,SelectBrands]); 


  const listaLineas= lineas.map(item => item.Linea);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === 'number' ? value.split(',') : value,
    );
    dispatch(selectedLines(event.target.value))
  };

  return (
    <div>
   
      <FormControl sx={{ m: 1, width: 180, mt: 3 }}>
        <Select
          multiple
          displayEmpty
          value={personName}
          onChange={handleChange}
          input={<OutlinedInput />}
          renderValue={(selected) => {
            if (selected.length === 0) {
              return <em>Línea</em>;
            }

            return selected.join(', ');
          }}
          MenuProps={MenuProps}
          inputProps={{ 'aria-label': 'Without label' }}
        >
          <MenuItem disabled value="">
            <em>Línea</em>
          </MenuItem>
          {listaLineas.map((name, index) => (
            <MenuItem
              key={index}
              value={name}
              style={getStyles(name, personName, theme)}
            >
              {name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
 
};
