/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";
// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import { StyledEngineProvider } from '@mui/material/styles';

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ReportsBarChart from "examples/Charts/BarCharts/ReportsBarChart";
import ReportsLineChart from "examples/Charts/LineCharts/ReportsLineChart";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";

// Dashboard components
import Projects from "layouts/dashboard/components/Projects";
import OrdersOverview from "layouts/dashboard/components/OrdersOverview";

import { useApiFetch } from "hooks/useApiFetch";
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { brandSelected } from "layouts/store/brandInventorySlice";
import { lineSelected } from "layouts/store/lineInventorySlice";
import PageNotFound from "layouts/notFound/PageNotFound";
//import { selectedBrands } from "layouts/store/listBrandSlice";



const key = 'b8d89953f379f97616201f31e173bab6d7ea444f0b7adbef2ccc7c7ec8cb6a34'

function Dashboard() {

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(brandSelected('A'))
    dispatch(lineSelected('B'))
  }, [dispatch]);


  const mesSeleccionadoNombre = useSelector((state) => state.mes.name);
  const MarcaSeleccionada = useSelector((state) => state.brand.value);
  const SelectBrands = useSelector((state) => state.brands.value);
  const SelectLines = useSelector((state) => state.lines.value);
  const selectedYears = useSelector((state) => state.years.value);
  const selectedMonths = useSelector((state) => state.months.value);

  const { get } = useApiFetch();
  const [chartDataNew, setChartDataNew] = useState([]);
  const [vehiculos, setVehiculos] = useState([]);
  const [marcas, setMarcas] = useState([]);
  const [marcasLineas, setMarcasLineas] = useState([]);

  
  useEffect(() => {
  
    const fetchData = async () => {
      try {

        let responseApi;
        
        responseApi = await get(`/api/Boards/GetTotalVehiculoNew?listaAnios=${JSON.stringify(selectedYears)}&listaMeses=${JSON.stringify(selectedMonths)}&listaMarcas=[${SelectBrands}]&listaLineas=[${SelectLines}]&key=${key}`)
        setVehiculos(responseApi)

        responseApi = await get(`/api/Boards/GetTotalMarcaNew?listaAnios=${JSON.stringify(selectedYears)}&listaMeses=${JSON.stringify(selectedMonths)}&listaMarcas=[${SelectBrands}]&key=${key}`)
        setMarcas(responseApi)

        responseApi = await get(`/api/Boards/GetAllSalesGeneralNew?listaAnios=${JSON.stringify(selectedYears)}&listaMeses=${JSON.stringify(selectedMonths)}&listaMarcas=[${SelectBrands}]&listaLineas=[${SelectLines}]&key=${key}`)
        setMarcasLineas(responseApi)

        responseApi = await get(`/api/Boards/GetAllSalesForMonthNew?listaAnios=${JSON.stringify(selectedYears)}&listaMeses=${JSON.stringify(selectedMonths)}&listaMarcas=[${SelectBrands}]&listaLineas=[${SelectLines}]&key=${key}`)
        setChartDataNew(responseApi)

      } catch (error) {
        console.error('Error fetching data:', error);
      }
  };

  fetchData();
  }, [selectedYears, selectedMonths, SelectBrands,SelectLines]);



if(chartDataNew === 'error'|| vehiculos === 'error' || marcas === 'error' || marcasLineas === 'error'){
  return (
    <div>
      <PageNotFound/>
    </div>
  )
}
if(chartDataNew.length === 0 || vehiculos.length === 0 || marcas.length === 0 || marcasLineas.length === 0){
  return (
    <div>
      <PageNotFound/>
    </div>
  )
}




  const añosUnicos = new Set();
  chartDataNew.forEach(item => {
    añosUnicos.add(item.AñoTransaccion);
  });

  const mesesUnicos = new Set();
  chartDataNew.forEach(item => {
    mesesUnicos.add(item.MesTransaccion);
  });

// Convertir el conjunto a un array
const añosArray = Array.from(añosUnicos);
const mesesArray = Array.from(mesesUnicos);

// Asignar los años a variables individuales
let año1, año2, año3;
if (añosArray.length >= 1) {
  año1 = añosArray[0];
}
if (añosArray.length >= 2) {
  año2 = añosArray[1];
}
if (añosArray.length >= 3) {
  año3 = añosArray[2];
}


let utilidad;
let venta;


//const mes = chartDataNew.map(item => item.MesTransaccion) 
const dataYearOne = chartDataNew.filter(item => item.AñoTransaccion === año1).map(item => Math.round(item.Total/1000000));
const dataYearTwo = chartDataNew.filter(item => item.AñoTransaccion === año2).map(item => Math.round(item.Total/1000000));
const dataYearThree = chartDataNew.filter(item => item.AñoTransaccion === año3).map(item => Math.round(item.Total/1000000));


if (mesSeleccionadoNombre === ''){
  utilidad = chartDataNew.map(item => item.Utilidad)
  venta = chartDataNew.map(item => item.Total/1000000)
}else{

  utilidad = chartDataNew.filter(item => item.MesTransaccion === mesSeleccionadoNombre).map(item => item.Utilidad);
  venta = chartDataNew.filter(item => item.MesTransaccion === mesSeleccionadoNombre).map(item => item.Total/1000000);
}

const cantidadVehiculo = vehiculos.map(item => item.Total_vehiculos)
const listaMarcas = marcas.map(item => item.Marca)
const totalxMarca = marcas.map(item => Math.round(item.Total/1000000))
const m = mesesArray.length  > 0  ? mesesArray:["Enero"]
const d = dataYearOne.length > 0 ? dataYearOne:[20]
const da = dataYearTwo.length > 0 ? dataYearTwo:[20]
const dat = dataYearThree.length > 0 ? dataYearThree:[20]
const u = utilidad.length > 0 ? utilidad:[5]
const c = cantidadVehiculo.length > 0 ? cantidadVehiculo:[0]
const ma = listaMarcas.length  > 0  ? listaMarcas:["MARCA"]
const t = totalxMarca.length > 0 ? totalxMarca:[0]

const sales = {
  labels: m,
  datasets: [
    {
      label: año1,
      data: d,
    },
    {
      label: año2,
      data: da,
    },
    {
      label: año3,
      data: dat,
    }
  ],
};


const totalSales = venta.reduce((total, valor) => total + valor, 0);
const totalSalesM = Math.round(totalSales)

const totalUtilidad = u.reduce((total, valor) => total + valor, 0);
const totalUtilidadM = Math.round(totalUtilidad/1000000)


const sumaFormateada = totalSalesM.toLocaleString('es-CO', {
  style: 'currency',
  currency: 'COP' ,
  minimumFractionDigits: 0
});

const utilidadFormateada = totalUtilidadM.toLocaleString('es-CO', {
  style: 'currency',
  currency: 'COP' ,
  minimumFractionDigits: 0
});

const chartBar= {
  labels: ma,
  datasets: { label: "Sales", data: t },
};

// MARCA -LÍNEA MÁS VENDIDA

const maLi = marcasLineas.length  > 0  ? marcasLineas:[{"MARCA":'A',"Linea":"B"}]
const marcaYLinea =  maLi[0].Marca +" "+ maLi[0].Linea;


  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="dark"
                icon="weekend"
                title="Vehículos"
                count={c}
                // percentage={{
                //   color: "success",
                //   amount: "+55%",
                //   label: "que el mes pasado",
                // }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                icon="leaderboard"
                title="Venta"
                count={`${sumaFormateada} M`}
                // percentage={{
                //   color: "success",
                //   amount: "+3%",
                //   label: "than last month",
                // }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="success"
                icon="store"
                title="Utilidad"
                count={`${utilidadFormateada} M`}
                // percentage={{
                //   color: "success",
                //   amount: "+1%",
                //   label: "than yesterday",
                // }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="primary"
                icon="person_add"
                title="Vehículo más vendido"
                count={marcaYLinea}
                // percentage={{
                //   color: "success",
                //   amount: "30%",
                //   label: "",
                // }}
              />
            </MDBox>
          </Grid>
        </Grid>
        <MDBox mt={4.5}>
          <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={6}>
              <MDBox mb={3}>
                <ReportsLineChart
                  color="success"
                  title="Ventas por Mes vs Año anterior"
                  // description={
                  //   <>
                  //     (<strong>+15%</strong>) increase in today sales.
                  //   </>
                  // }
                  // date="updated 4 min ago"
                  chart={sales}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={4} lg={6}>
              <MDBox mb={3}>
                <ReportsBarChart
                  color="info"
                  title="Ventas por Marca"
                  // description="Last Campaign Performance"
                  // date="campaign sent 2 days ago"
                  chart={chartBar}
                />
              </MDBox>
            </Grid>
           
            {/* <Grid item xs={12} md={6} lg={4}>
              <MDBox mb={3}>
                <ReportsLineChart
                  color="dark"
                  title="completed tasks"
                  description="Last Campaign Performance"
                  date="just updated"
                  chart={tasks}
                />
              </MDBox>
            </Grid> */}
            
          </Grid>
        </MDBox>
        <MDBox>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={8}>
              <Projects />
              {/* <StyledEngineProvider injectFirst>
                <Demo />
              </StyledEngineProvider> */}
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <OrdersOverview />
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Dashboard;
