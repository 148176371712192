import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import { useNavigate } from 'react-router-dom';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import { green, pink } from '@mui/material/colors';

import { useSelector } from 'react-redux';


import * as XLSX from 'xlsx'
import { useApiFetch } from 'hooks/useApiFetch';
import PageNotFound from 'layouts/notFound/PageNotFound';


const pages = ['Ventas', 'Inventario'];
const settings = ['Exportar Ventas', 'Exportar Inventario'];
const key = 'b8d89953f379f97616201f31e173bab6d7ea444f0b7adbef2ccc7c7ec8cb6a34'
function ResponsiveAppBar() {

  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);

   const { get } = useApiFetch();

   const selectedYears = useSelector((state) => state.years.value);
   const selectedMonths = useSelector((state) => state.months.value);
   const SelectBrands = useSelector((state) => state.brands.value);
   const SelectLines = useSelector((state) => state.lines.value);
   const MarcaSeleccionadaInventario = useSelector((state) => state.brandsInventory.value);
   const lineaSeleccionadaInventario = useSelector((state) => state.linesInventory.value);

  const navigate = useNavigate();

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = (page) => {

    if(typeof page === 'object'){

      if(page.target.innerText === "VENTAS"){
        navigate("dashboard");
      }

      if(page.target.innerText === "INVENTARIO"){
          navigate("tables");
      }
    }

    if(typeof page === 'string'){

      if(page === "Ventas"){
        navigate("dashboard");
      }

      if(page === "Inventario"){
          navigate("tables");
      }
    }
    
    setAnchorElNav(null);
    
  };

  const handleCloseUserMenu = (setting) => {

    if(setting === "Exportar Ventas"){
      handleDownload();
    }

    if(setting === "Exportar Inventario"){
      handleDownloadInventario();
    }
   
    setAnchorElUser(null);
  };

  const [loading, setLoading] = React.useState(false);
  const notify = () => toast.warning("Wow so easy!",{theme:'colored'});
  const titulo = [{A : "Reporte de Ventas"},{}];
  const longitudes = [ 15,12,15,7,15,15,15,15,22,20];

  const tituloInv = [{A : "Reporte de Inventario"},{}];
  const longitudesInv = [ 15,12,20,7,15,30,15,15,15,20];

  
  const creandoArchivo = (dataFinal, proceso) => {
    const libro = XLSX.utils.book_new();

    const hoja = XLSX.utils.json_to_sheet(dataFinal, { skipHeader:true});

    hoja["!merges"]= [
        XLSX.utils.decode_range("A1:J1"),
        XLSX.utils.decode_range("A2:J2"),
        
    ];

    let propiedades = [];

    if(proceso === "Ventas"){
      longitudes.forEach((col) => {
        propiedades.push({
            width: col,
        });
    });
    }

    if(proceso === "Inventario"){
      longitudesInv.forEach((col) => {
        propiedades.push({
            width: col,
        });
      });
    }

    hoja["!cols"] = propiedades;

    XLSX.utils.book_append_sheet(libro, hoja, proceso);

    let titulo = "";
    if(proceso === "Ventas"){
      titulo = "Reporte_Venta.xlsx"
    }

    if(proceso === "Inventario"){
      titulo = "Reporte_Inventario.xlsx"
    }

    XLSX.writeFile(libro, titulo);

    

  }


  const handleDownload = async () => {
    setLoading(true);
    

    const data = await get(`/api/Boards/GetVentaExcelNew?listaAnios=${JSON.stringify(selectedYears)}&listaMeses=${JSON.stringify(selectedMonths)}&listaMarcas=[${SelectBrands}]&listaLineas=[${SelectLines}]&key=${key}`)
    //const data = await get(`/api/Boards/GetVentaExcelNew?listaAnios=${selectedYears}&listaMeses=${selectedMonths}&marca=${MarcaSeleccionada}&linea=${lineaSeleccionada}&key=${key}`)

    if(data === 'error'){
      notify()
      return (
        <>

        <div>
          {/* <PageNotFound/> */}
         
        </div>
        <ToastContainer />
        </>
       
      )
    }

    let tabla = [
        {
            A:"Placa",
            B:"Marca",
            C:"Linea",
            D:"Modelo",
            E:"Valor Real",
            F:"Valor Venta",
            G:"Gastos",
            H:"Utilidad",
            I:"Fecha Venta",
        },
    ];

    data.forEach( (venta) => {
        tabla.push({
            A: venta.Placa,
            B: venta.Marca,
            C: venta.Linea,
            D: venta.Modelo,
            E: venta.ValorReal,
            F: venta.ValorVenta,
            G: venta.Gastos,
            H: venta.Utilidad,
            I: venta.FechaVenta,
        });
    });

    const dataFinal = [...titulo, ...tabla]

    setTimeout(()=>{
        creandoArchivo(dataFinal,"Ventas");
        setLoading(false);
    },1000);
   
  };

  const handleDownloadInventario = async () => {
    setLoading(true);
    const dataInventario = await get(`/api/Boards/GetInventarioNew?listaMarcas=[${MarcaSeleccionadaInventario}]&listaLineas=[${lineaSeleccionadaInventario}]&key=${key}`)
   
    if(dataInventario === 'error'){
      notify()
      return (
        <>

        <div>
          {/* <PageNotFound/> */}
         
        </div>
        <ToastContainer />
        </>
       
      )
    }

    let tabla = [
        {
            A:"Placa",
            B:"Marca",
            C:"Linea",
            D:"Modelo",
            E:"Tipo Vehículo",
            F:"Propietario",
            G:"Celular",
            H:"Correo",
            I:"Valor Real",
            J:"Fecha Ingreso",
        },
    ];

    dataInventario.forEach( (inv) => {
        tabla.push({
            A: inv.Placa,
            B: inv.Marca,
            C: inv.Linea,
            D: inv.Modelo,
            E: inv.TipoVehiculo,
            F: inv.Propietario,
            G: inv.Celular,
            H: inv.Correo,
            I: inv.ValorReal,
            J: inv.FechaIngreso,
        });
    });

    const dataFinal = [...tituloInv, ...tabla]

    setTimeout(()=>{
        creandoArchivo(dataFinal,"Inventario");
        setLoading(false);
    },1000);
   
  };

  return (
    <AppBar position="static">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          {/* <AdbIcon sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }} /> */}
            {/* <Avatar alt="Travis Howard" src={logoArtur} style={{width:50, height:50}}/> */}
          {/* <Avatar sx={{ bgcolor: green[500] }}>
                 
                  <DownloadForOfflineIcon sx={{ width: 38, height: 38}}/>
          </Avatar>  */}
          <Typography
            variant="h6"
            noWrap
            component="a"
            href="#app-bar-with-responsive-menu"
            sx={{
              mr: 2,
              display: { xs: 'none', md: 'flex' },
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
           
          </Typography>

          {/* <NavLink  to='tables'>
            ruta
          </NavLink> */}

          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              {pages.map((page) => (
                <MenuItem key={page} onClick={() => handleCloseNavMenu(page)}>
                  <Typography textAlign="center">{page}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
          {/* <AdbIcon sx={{ display: { xs: 'flex', md: 'none' }, mr: 1 }} /> */}
          <Typography
            variant="h5"
            noWrap
            component="a"
            href="#app-bar-with-responsive-menu"
            sx={{
              mr: 2,
              display: { xs: 'flex', md: 'none' },
              flexGrow: 1,
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            
          </Typography>

          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            {pages.map((page) => (
              <Button
                key={page}
                onClick={handleCloseNavMenu}
                sx={{ my: 2, color: 'white', display: 'block' }}
              >
                {page}
              </Button>
            ))}
          </Box>

          <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="Exportar Data">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar sx={{ bgcolor: green[500] }}>
                  {/* <AssignmentIcon /> */}
                  <DownloadForOfflineIcon sx={{ width: 38, height: 38}}/>
                </Avatar>

              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {settings.map((setting) => (
                <MenuItem key={setting} onClick={() => handleCloseUserMenu(setting)}>
                  <Typography textAlign="center" fontSize="14px">{setting}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default ResponsiveAppBar;