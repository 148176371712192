import * as React from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

const PageNotFound = () => {
  return (
    <>

      <Card sx={{ maxWidth: 445 }} style={{marginLeft:480}}>
        <CardMedia
          sx={{ height: 140 }}
          image="https://i.imgur.com/qIufhof.png"
          title="Pérdida de conexión"
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            Pérdida de conexión
          </Typography>
          <Typography variant="body2" color="text.secondary">
            Se perdió conexión con el servidor, por favor comunicarse con el
            administrador o intente más tarde.
          </Typography>
        </CardContent>
        {/* <CardActions>
          <Button size="small">Share</Button>
          <Button size="small">Learn More</Button>
        </CardActions> */}
      </Card>
    </>
  );
};

export default PageNotFound;
