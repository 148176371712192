//import { useState } from 'react';
import { Global } from '../helpers/Global';

export const useApiFetch = () => {

   // const [data_, SetData] = useState()

  const get = async ( endpoint) => {     

    try {
        const data = await generico(endpoint);
        return data;

    } catch (error) {
        return 'error'
    }
     
  };

  const postOutBody = async (endpoint ) => {  
      const data = await generico(endpoint, "POST");
      return data;
  };

  const post = async (endpoint, json ) => { 
      const request = await fetch(Global.url + endpoint, {
          method: "POST",
          headers: {
              "Content-Type": "application/json",
          },
          body: JSON.stringify(json),
          });
      
          const data = await request.json(); //OK, TRUE
          return data
  };

  const put = async (endpoint, json ) => { 
    const request = await fetch(Global.url + endpoint, {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
        },
        body: json,
        });
    
        const data = await request.json(); //OK, TRUE
        return data
  };

  const putWithParameters = async (endpoint) => { 
    const request = await fetch(Global.url + endpoint, {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
        },
        });
    
        const data = await request.json(); //OK, TRUE
        return data
  };

 const generico = async (endpoint) => {  

    try {
        const requestOptions = {
            method: "GET",
            redirect: "follow"
        };
        let apiResult  ;
    
        await fetch(Global.api_url+endpoint, requestOptions)
        .then((response) => response.text())
        .then((result) =>  {
            const data = JSON.parse(result)
          
            apiResult = data.answerList;
        })
        
        
        if(!apiResult){
            return 'error';
        }
        return apiResult;
    } catch (error) {
       
        return 'error'
    }
    
 };

 
  return {
    get,
    post,
    postOutBody,
    put,
    putWithParameters
  }
}