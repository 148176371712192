import { createSlice } from '@reduxjs/toolkit'

const fechaActual = new Date();

// Obtener el año actual
const añoActual = fechaActual.getFullYear();
export const listYearsSlice = createSlice({
  name: 'years',
  initialState: {
    value: [añoActual],
  },
  reducers: {
    
    selectedYears: (state, action) => {

      if (action.payload.length > 0){
        state.value = action.payload
      } else{
        state.value = [añoActual]
      } 
      
    },
  },
})

// Action creators are generated for each case reducer function
export const { selectedYears } = listYearsSlice.actions

export default listYearsSlice.reducer